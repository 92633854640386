var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "700px" } },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.btnHidden,
              expression: "btnHidden",
            },
          ],
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SPEC030.008"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.011")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      attrs: { name: "req", id: "req" },
                      on: { change: _vm.changeReqNo },
                    },
                    _vm._l(_vm.detail, function (vo, i) {
                      return _c("option", { key: i, domProps: { value: i } }, [
                        _vm._v(_vm._s(vo.reqNo)),
                      ])
                    }),
                    0
                  ),
                ]),
                _c("td", {
                  staticClass: "border_left_none",
                  attrs: { colspan: "2" },
                }),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSDO800.004")))]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.detailPort.polPortNm) +
                      "," +
                      _vm._s(_vm.detailPort.polPortCtrCd)
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSDO800.005")))]),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.detailPort.podPortNm) +
                      "," +
                      _vm._s(_vm.detailPort.podPortCtrCd)
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.014")))]),
                _vm.detail[_vm.idx].tmprUnit == "C"
                  ? _c("td", [
                      _vm._v(
                        _vm._s(_vm.detail[_vm.idx].strFlsTmpr) +
                          " ~ " +
                          _vm._s(_vm.detail[_vm.idx].endFlsTmpr) +
                          " ℃"
                      ),
                    ])
                  : _vm.detail[_vm.idx].tmprUnit == "F"
                  ? _c("td", [
                      _vm._v(
                        _vm._s(_vm.detail[_vm.idx].strFlsTmpr) +
                          " ~ " +
                          _vm._s(_vm.detail[_vm.idx].endFlsTmpr) +
                          " ℉"
                      ),
                    ])
                  : _c("td"),
                _c("th", [_vm._v("F/P")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(" " + _vm._s(_vm.detail[_vm.idx].flsTmpr) + " "),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.015")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.detail[_vm.idx].dsc1)),
                ]),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.SPEC030.016"))),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.017"))),
                ]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.detail[_vm.idx].dsc2)),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.018")))]),
                _c("td", [_vm._v(_vm._s(_vm.detail[_vm.idx].imdunNo))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.019")))]),
                _c("td", [_vm._v(_vm._s(_vm.detail[_vm.idx].imoClasCd))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.020")))]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.changePkgGrpCdToNm(_vm.detail[_vm.idx].packingGrp)
                    )
                  ),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.021")))]),
                _c("td", [_vm._v(_vm._s(_vm.detail[_vm.idx].prerShpnNm))]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.022")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(_vm._s(_vm.detail[_vm.idx].dsc3)),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.023")))]),
                _c("td", [_vm._v(_vm._s(_vm.detail[_vm.idx].actShprCstEnm))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.010")))]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.$ekmtcCommon.changeNumberFormat(
                        _vm.detail[_vm.idx].amt,
                        { isComma: true }
                      )
                    ) +
                      " " +
                      _vm._s(_vm.detail[_vm.idx].curCd)
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.024")))]),
                _c("td", [_vm._v(_vm._s(_vm.detail[_vm.idx].bkgShprCstNm))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.025")))]),
                _c("td", [
                  _c(
                    "a",
                    {
                      class: _vm.$ekmtcCommon.isEmpty(_vm.uploadParam)
                        ? "button sm gray file"
                        : "button sm blue",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.openFileUploader()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.SPEC030.031")))]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.SPEC030.026")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm.detail[_vm.idx].apvStsCd === "01"
                    ? _c("span", { staticClass: "label green md minwid60" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK140.004"))),
                      ])
                    : _vm.detail[_vm.idx].apvStsCd === "02"
                    ? _c("span", { staticClass: "label blue md minwid60" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK140.002"))),
                      ])
                    : _vm.detail[_vm.idx].apvStsCd === "03"
                    ? _c("span", { staticClass: "label red md minwid60" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK140.003"))),
                      ])
                    : _c("span", { staticClass: "label gray md minwid60" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBK140.004"))),
                      ]),
                ]),
              ]),
            ]),
          ]),
          _c("ul", { staticClass: "bul_list_sm lh_short" }, [
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.027")) + " ")]),
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.028")) + " ")]),
            _c("li", [_vm._v(" " + _vm._s(_vm.$t("msg.SPEC030.029")) + " ")]),
          ]),
        ]),
        _c(
          "div",
          { attrs: { id: "layerPopRe" } },
          [
            _c(
              "win-layer-pop",
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.customPop },
                      on: {
                        close: _vm.closePopupWrap,
                        selectFunc: _vm.selectFunc,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col"),
      _c("col", { attrs: { width: "170px" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }