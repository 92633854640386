<template>
  <div class="popup_wrap" style="width:700px">
    <!-- popup_wrap : style="width:700px height:550px" 참고용, 개발시 제거 -->
    <button v-show="btnHidden" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.SPEC030.008') }}</h1>
      <!-- <div class="pop_scroll"> -->
      <div class="content_box">
        <!-- content_box -->

        <table class="tbl_row">
          <colgroup>
            <col width="150px"><col><col width="170px"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.SPEC030.011') }}</th>
              <td>
                <select name="req" id="req" @change="changeReqNo">
                  <option v-for="(vo, i) in detail" :key="i" :value="i">{{ vo.reqNo }}</option>
                </select>
              </td>
              <td colspan="2" class="border_left_none"></td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSDO800.004') }}</th>
              <td>{{ detailPort.polPortNm }},{{ detailPort.polPortCtrCd }}</td>
              <th>{{ $t('msg.CSDO800.005') }}</th>
              <td>{{ detailPort.podPortNm }},{{ detailPort.podPortCtrCd }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.014') }}</th>
              <td v-if="detail[idx].tmprUnit == 'C'">{{ detail[idx].strFlsTmpr }} ~ {{ detail[idx].endFlsTmpr }} ℃</td>
              <td v-else-if="detail[idx].tmprUnit == 'F'">{{ detail[idx].strFlsTmpr }} ~ {{ detail[idx].endFlsTmpr }} ℉</td>
              <td v-else></td>

              <th>F/P</th> <!--F/P(플래시 포인트)-->
              <td>
                {{ detail[idx].flsTmpr }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.015') }}</th>
              <td colspan="3">{{ detail[idx].dsc1 }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.016') }}<br /> {{ $t('msg.SPEC030.017') }}</th>
              <td colspan="3">{{ detail[idx].dsc2 }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.018') }}</th>
              <td>{{ detail[idx].imdunNo }}</td>
              <th>{{ $t('msg.SPEC030.019') }}</th>
              <td>{{ detail[idx].imoClasCd }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.020') }}</th>
              <td>{{ changePkgGrpCdToNm(detail[idx].packingGrp) }}</td>
              <th>{{ $t('msg.SPEC030.021') }}</th>
              <td>{{ detail[idx].prerShpnNm }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.022') }}</th>
              <td colspan="3">{{ detail[idx].dsc3 }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.023') }}</th>
              <td>{{ detail[idx].actShprCstEnm }}</td>
              <th>{{ $t('msg.SPEC030.010') }}</th>
              <td>{{ $ekmtcCommon.changeNumberFormat(detail[idx].amt, { isComma: true }) }} {{ detail[idx].curCd }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.024') }}</th>
              <td>{{ detail[idx].bkgShprCstNm }}</td>
              <th>{{ $t('msg.SPEC030.025') }}</th>
              <td>
                <a :class="$ekmtcCommon.isEmpty(uploadParam) ? 'button sm gray file' : 'button sm blue'" href="#" @click="openFileUploader()">{{ $t('msg.SPEC030.031') }}</a>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.SPEC030.026') }}</th>
              <td colspan="3">
                <span v-if="detail[idx].apvStsCd ==='01'" class="label green md minwid60">{{ $t('msg.CSBK140.004') }}</span>
                <span v-else-if="detail[idx].apvStsCd ==='02'" class="label blue md minwid60">{{ $t('msg.CSBK140.002') }}</span>
                <span v-else-if="detail[idx].apvStsCd ==='03'" class="label red md minwid60">{{ $t('msg.CSBK140.003') }}</span>
                <span v-else class="label gray md minwid60">{{ $t('msg.CSBK140.004') }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <ul class="bul_list_sm lh_short">
          <li>
            {{ $t('msg.SPEC030.027') }}
          </li>
          <li>
            {{ $t('msg.SPEC030.028') }}
          </li>
          <li>
            {{ $t('msg.SPEC030.029') }}
          </li>
        </ul>
      </div><!-- content_box // -->
      <!-- </div>pop_scroll // -->

      <div id="layerPopRe">
        <win-layer-pop>
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="customPop"
            @close="closePopupWrap"
            @selectFunc="selectFunc"
          />
        </win-layer-pop>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import RfDg from '@/api/rest/trans/rfDg'
import DownloadPop from '@/pages/trans/popup/SpecialRfDgDownloadPop'
import WinLayerPopWrap from '@/pages/trans/popup/WinLayerPopWrap'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'

export default {
    name: 'SpecialRfDgDtlPop',
    components: {
      RfDg,
      DownloadPop,
      WinLayerPopWrap,
      WinLayerPop
    },
    props: [
      'detail',
      'detailPort'
    ],
    data: function () {
        return {
          parentInfo: {},
          customComponent: null,
          customPop: null,
          selectFunc: null,
          uploadParam: [],
          uploadFileName: '',
          uploadFileInfo: [],
          state1: '',
          link: '',
          idx: 0,
          btnHidden: true
        }
    },
    watch: {
      detail: function () {
        this.changeReqNo({ target: { value: this.idx } })
      }
    },
    created () {
    },
    mounted: function () {
      console.log('mounted')
      //this.changeReqNo({ target: { value: 0 } })
    },
    methods: {
        changeReqNo (e) {
          this.idx = e.target.value
          this.uploadParam = []
          console.log(this.detail[this.idx].reqNo)
          console.log('mounted')
          RfDg.getFileList(this.detail[this.idx])
            .then(response => {
              console.log(response)
              const resp = response.data
              if (resp.length > 0) {
                for (let i = 0; i < resp.length; i++) {
                  this.uploadParam =
                  [...this.uploadParam,
                    {
                      originalName: resp[i].fileNm,
                      size: resp[i].fileSz,
                      fileId: 'http://trans.ekmtc:9000/trans/trans/rfdg/finfo?reqNo=' + resp[i].reqNo + '&fileId=' + resp[i].fileId
                    }
                  ]
                }
              }
            })
            .catch(err => {
              console.log(err)
            })
        },
        changePkgGrpCdToNm (pakgGrpCd) {
          let pakgGrpNm = ''

          //PG arrange
          if (pakgGrpCd === '01') {
            pakgGrpNm = 'III'
          } else if (pakgGrpCd === '02') {
            pakgGrpNm = 'II'
          } else if (pakgGrpCd === '03') {
            pakgGrpNm = 'I'
          } else if (pakgGrpCd === '04') {
            pakgGrpNm = ''
          }

          return pakgGrpNm
        },
        closePopupWrap () {
          // 상세 닫기 버튼 표시
          this.btnHidden = true
          this.$ekmtcCommon.layerClose('#layerPopRe .popup_dim')
        },
        openFileUploader () {
          // 상세 닫기 버튼 숨김
            this.btnHidden = false
          // if (!this.$ekmtcCommon.isEmpty(this.uploadParam)) {
            this.customComponent = DownloadPop
            this.customPop = this.uploadParam
            this.selectFunc = this.setUploadInfo
            this.$ekmtcCommon.layerOpen('#layerPopRe .popup_dim')
          // }
        },
        setUploadInfo (obj) {
          console.log(obj)
        }
    }
}

</script>

<style>
.popup_dim .layer_close {z-index: 2998 !important}
.popup_dim2 {z-index: 2999 !important}
</style>
